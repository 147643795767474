import { ChnSortSearchTable, useFetch } from "@chainless/chainless-react";
import '../index.css';
import { UpdateItem } from "../components/UpdateItem";
import { useEffect, useState } from "react";
import { ImagePopup } from "../components/ImagePopup";
import { UpdateMelding } from "../components/UpdateMelding";
import { MeldingCharts } from "../components/MeldingCharts";

export const MeldingVoorvalPage = () => {
  const { data: meldingen, error, loading, fetchData } = useFetch('/api/meldingen');
  const [selectedId, setSelectedId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (selectedId) {
      setSelectedItem(meldingen.find((el) => el.id === selectedId));
    }
  }, [selectedId, meldingen]);

  const refreshAll = () => {
    fetchData();
  };

  const formatDate = (date) => {
    if (date) {
      return new Date(date * 1000).toLocaleDateString('nl-NL');
    }
  };

  const headers = [
    { key: 'timestamp', name: 'Datum melding', valueMapper: (timestamp) => new Date(timestamp * 1000).toLocaleDateString('nl-NL') },
    { key: 'prioriteit', name: 'Prio', valueMapper: (prioriteit) => prioriteit ? prioriteit : '' },
    { key: 'project', name: 'Project', valueMapper: (project) => project ? project : '' },
    { key: 'projectLeider', name: 'Projectleider', valueMapper: (projectleider) => projectleider ? projectleider : '' },
    { key: 'beschrijving', name: 'Beschrijving', valueMapper: (beschrijving) => beschrijving ? beschrijving : '' },
    { key: 'locatie', name: 'Locatie', valueMapper: (locatie) => locatie ? locatie : '' },
    { key: 'melder', name: 'Melder', valueMapper: (melder) => melder ? melder : '' },
    { key: 'id', name: 'Extra info', valueMapper: (id) => <UpdateItem id={id} selectedId={selectedId} setSelectedId={setSelectedId} refresh={refreshAll} item={'melding'} />, search: false }
  ];

  return (
    <>
      {loading && <p>Meldingen ophalen...</p>}
      {error && <p>Error bij ophalen meldingen: {error}</p>}
      {meldingen && meldingen.length > 0 &&
        <>
          <div className="inhoud">
            <div className="tabel" style={{ minHeight: '55vh' }}>
              <h1>Alle meldingen</h1>
              <div className="table">
                <ChnSortSearchTable headers={headers} data={meldingen} pageSize={10} />
              </div>
            </div>
            <div className="details">
              {selectedItem && !update &&
                <>
                  <h1>Extra info</h1>
                  <button onClick={() => setUpdate(true)}>Aanpassen</button>
                  <div className="item">
                    <p className="label">Tijdsplanning</p>
                    <p>{formatDate(selectedItem.tijdsplanning)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Voltooid op</p>
                    <p>{formatDate(selectedItem.voltooidOp)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Genomen maatregelen</p>
                    <p>{selectedItem.genomenMaatregelen}</p>
                  </div>
                  <div className="item">
                    <p className="label">Toevoeging</p>
                    <p>{selectedItem.toevoeging}</p>
                  </div>
                  <div className="item">
                    <p className="label">Werk voortzetting</p>
                    <p>{selectedItem.werkVoortzetting === true ? 'JA' : 'NEE'}</p>
                  </div>
                  <div className="item" style={{ marginBottom: 10 }}>
                    <p className="label">Prioriteit</p>
                    <p>{selectedItem.prioriteit}</p>
                  </div>
                  <div>
                    {selectedItem.foto.map(foto => (
                      <ImagePopup key={foto} src={foto !== '' ? `/api/images/${foto}` : null} />
                    ))}
                  </div>
                </>
              }
              {update &&
                <UpdateMelding id={selectedId} setUpdate={setUpdate} refresh={refreshAll} />
              }
            </div>
          </div>
          <div className="grafieken">
            <MeldingCharts data={meldingen} />
          </div>
        </>
      }
    </>
  );
};